<template>
  <div class="w-100 d-flex justify-content-center">
    <HelpSheet :sheet="helpSheet" v-if="gotSheet" class="max-w-xl"/>
  </div>
</template>

<script>
import HelpSheet from "@/components/help-sheets/HelpSheet";

export default {
  name: "HelpSheetPage",
  components: {
    HelpSheet
  },
  data() {
    return {
      helpSheet: {},
      gotSheet: false
    }
  },
  created() {
    this.$store.dispatch("helpSheet/get", this.$route.params.sheetId)
    .then(response => {
      this.helpSheet = response.data
      this.gotSheet = true
    })
    .catch(error => {
      if(error.response.status == 404) {
        this.$router.push({name: "error-404"})
      }
      if (error.response.status == 429) {
        this.$vs.notify({
          time: 10000,
          title: "Limite atteinte",
          text: "Vous avez atteint la limite quotidienne de visualisation de fiches pratiques, pour continuer, veuillez créer un compte"
        })
        this.$router.push({name: "accueil"})
      }
    })
  }
}
</script>

<style lang="scss">

</style>
